import React from 'react'
import { Link } from 'react-router-dom'

const Nav = () => {
  return (
    <nav className='navbar navbar-expand-lg bg-body-tertiary'>
        <div className="container">
          <Link to="/" className='navbar-brand'><img src={`0000.png`} alt='eP' className='brand-logo' style={{marginLeft:'20px'}}/></Link>
          <div className='nav justify-content-end'>
            <Link to="/about" className='nav-link' style={{color:'#00FFFF'}}>ABOUT</Link>
            <Link to="/project" className='nav-link' style={{color:'#00FFFF'}}>PROJECT</Link>
            <Link to="/contact" className='nav-link' style={{color:'#00FFFF'}}>CONTACT</Link>
          </div>
        </div>
    </nav>
  )
}

export default Nav