import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div style={{marginTop:'50px'}}>
      <img src={`0002.png`} alt='Home' className='img-fluid'/>
      {/* <div style={{backgroundImage: `url('0002.png')`, backgroundRepeat:'no-repeat',height:'200px'}}></div> */}
      <div style={{padding: '20px'}}>
        <h4>I build things for the web</h4>
      </div>
      <div style={{padding: '20px', marginTop:'100px'}}>
        <h4 style={{fontWeight:'lighter'}}>About me</h4>
        <Link to='/about' className='nav-link'><img id="img-link" src={`0005.png`} alt="Prasetyo Elly" className="brand-logo" /></Link>
      </div>
    </div>
  )
}

export default Home