import React from 'react'

const Contact = () => {
  return (
    <div style={{ marginTop:'200px' }}>
      <div className="text-center">
        <h5>Let's Get In Touch!</h5>
        <h5>Ready to start your next project with me?</h5>
        <h5>Give me a call or send me an email and I will get back to you as soon as possible!</h5>
      </div>

      <div className='d-flex justify-content-center py-4'>
        <a href="https://api.whatsapp.com/send?phone=6281991098980" target='_blank' className='nav-link'><img src={`whatsapp.png`} className='link-icon' alt="whatsapp"/></a>
        <a href="mailto: estehtawar5000@gmail.com" target='_blank' className='nav-link'><img src={`mail.png`} className='link-icon' alt="mail"/></a>
        <a href="http://www.instagram.com/eltyo_" target='_blank' className='nav-link'><img src={`instagram.png`} className='link-icon' alt="instagram"/></a>
        <a href="http://www.linkedin.com/in/elly-prasetyo" target='_blank' className='nav-link'><img src={`linkedin.png`} className='link-icon' alt="linkedin"/></a>
      </div>
    </div>
  )
}

export default Contact