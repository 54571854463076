import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as BrowserRouter, Routes, Route, Navigate, useNavigate  } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Nav from './layout/Nav';
import Contact from './pages/Contact';
import { useState } from 'react';
import Project from './pages/Project';


function App() {
  const [bg,setBg] = useState('#121212')
  var weel = 0;

  
  return (
    <BrowserRouter>
      <div onWheel={(val)=>{
        weel = weel + val.deltaY;

        if(weel < 0) weel = 0;
        if(weel > 1320) weel = 1320;
      }}>
        <Nav/>
        <div className='container' style={{marginTop:'50px'}} >
          <Routes>
            <Route exact path='/' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/project' element={<Project/>}/>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
