import React from 'react'
import { TypeAnimation } from 'react-type-animation'

const About = () => {
  return (
    <div className='about'>
      <div className='text-center'>
        <img src={`photo.png`} alt="photo" className="pp" />
      </div>
      <div style={{width:'50px'}}></div>
      <div>
        <TypeAnimation
          sequence={[
            `Hello I'm Elly Prasetyo`, // Types 'One'
            1000, 
            `I'm Web Developer`,
            2000,
            `I have more than 4 year of the experience on web development. `,
            3000,
            `Focused professional with 4+ years of experience and a proven
            knowledge of cross-functional team.`,
            4000,
            `For backend I  prefer to Laravel or Express JS and in frontend I prefer to React JS for web and React Native for mobile`,
            5000,
            `I'm very good using PostgreSQL for database`,
            6000,
            `Hello I'm Elly Prasetyo. \n I'm Web Developer. \n I have more than 4 year of the experience on web development. \n Focused professional with 4+ years of experience and a proven
            knowledge of cross-functional team. \n For backend I  prefer to Laravel or Express JS and in frontend I prefer to React JS for web and React Native for mobile. \n
            I'm very good using PostgreSQL for database`,
            () => {
              // console.log('Sequence completed'); // Place optional callbacks anywhere in the array
            }
          ]}
          wrapper="span"
          cursor={true}
          repeat={Infinity}
          style={{ fontSize: '2em', display: 'inline-block', color:'#00FFFF', fontWeight:'inherit', textJustify:'inter-word' }}
        />
      </div>
    </div>
  )
}

export default About