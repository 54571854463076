import React from 'react'

const Project = () => {
  return (
    <div>
      <div className="text-center">
        <h3>Projects made by CI, Webix javaScript and PostgreSQL</h3>
      </div>
      <div className="row">
        <div className="col-sm">
          <img src={`p11.jpg`} alt="p11" className='img-project' />
          <h5 className="text-center">Finance and Budget - Vallourec Cost Project</h5>
        </div>
        <div className="col-sm">
          <img src={`p22.jpg`} alt="p22" className='img-project' />
          <h5 className="text-center">CAPEX & OPEX List - Vallourec Cost Project</h5>
        </div>
      </div><br />
      <div className="row">
        <div className="col-sm">
          <img src={`p33.jpg`} alt="p33" className='img-project' />
          <h5 className="text-center">Animation Document (SME View) - Labtech Content Sandbox</h5>
        </div>
        <div className="col-sm">
          <img src={`p44.jpg`} alt="p44" className='img-project' />
          <h5 className="text-center">Animation Document (User View) - Labtech Content Sandbox</h5>
        </div>
      </div><br />

      <div className="text-center">
        <h3>Projects made by Unity 3D</h3>
      </div>
      <div className="row">
        <div className="col-sm">
          <img src={`p1.jpg`} alt="p1" className='img-project' />
          <h5 className="text-center">Component Description (AR) - Labtech Academy</h5>
        </div>
        <div className="col-sm">
          <img src={`p3.jpg`} alt="p3" className='img-project' />
          <h5 className="text-center">HVAC Game Simulation - Labtech Academy</h5>
        </div>
      </div><br />
      <div className="row">
        <div className="col-sm">
          <img src={`p6.jpg`} alt="p6" className='img-project' />
          <h5 className="text-center">Component Description - Game Simulation - Labtech Academy</h5>
        </div>
        <div className="col-sm">
          <img src={`p9.jpg`} alt="p9" className='img-project' />
          <h5 className="text-center">Operating Simulation - Labtech Academy</h5>
        </div>
      </div><br />
    </div>
  )
}

export default Project